import React, { useEffect, useRef, useState } from 'react';
import { Box, Divider, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useLocalStorage } from '../../../auth/useLocalStorage';
import { headerData } from '../../../data/headerCostum';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import ImageConverter from './image_converter';

const PrintPDFAdminInternet = ({ execute }) => {
  let { id } = useParams();
  const [dataLongwis, setDataLongwis] = useState(null);
  const [coords, setCoords] = useState(null);
  const [dokumentasiLOKASI, setdokumentasiLOKASI] = useState(null);


  const [downloaded, setDownloaded] = useState(false);

  const pdfRef = useRef();

  const DownloadPDF = useReactToPrint({
    content: () => pdfRef.current,
  })

  const formatDate = (value) => {
    const date_time = new Date(value);
    return (date_time.toISOString().split('T')[0]);
  }


  const getLaporanData = () => {
    fetch(process.env.REACT_APP_API_URL + "api/longwis/" + id, {
      method: "get",
      headers: headerData
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.nama_longwis) {
          setDataLongwis(res);
          setdokumentasiLOKASI(JSON.parse(res.LokLongwi.file_foto));
          setCoords(JSON.parse(res.lokasi));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (!dataLongwis) {
      getLaporanData()
    } else {
      if (execute && !downloaded) {
        DownloadPDF();
        setDownloaded(true);
      }
    }
  }, [dataLongwis, getLaporanData, DownloadPDF])

  return (
    <div ref={pdfRef}>
      <Grid container width={"297mm"}>
        {/* <Grid item container direction={"row"} sx={{
          marginX: 20,
          marginY: 5
        }}>
          <Grid item container xs={3} justifyContent={"center"} >
            <img src={Logo} width={110} alt='Logo Pemkot' />
          </Grid>
          <Grid item xs={9} container direction={'column'} alignItems={"center"} justifyContent={"center"}>
            <Grid item>
              <Typography variant='h3' fontSize={35} textTransform={'uppercase'} fontWeight={'700'}>
                {"Pemerintah Kota Makassar"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h5' fontSize={19} align='center' textTransform={'uppercase'} fontWeight={'700'}>
                {"Dinas Komunikasi dan Informatika Kota Makassar"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>
                {"Jalan Andi Pangeran Pettarani No. 62 Makassar 9032"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='body1'>
                {"Email: diskolminfo@makassarkota.go.id, HomePage: diskominfo.makassarkota.go.id"}
              </Typography>
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item sx={{
          background: "GrayText",
          height: "2px",
          width: "100%",
          marginBottom: 1,
          marginX: 10,
        }}>
          <Divider />
        </Grid>
      </Grid>
      {/* <Box height={50} sx={{
      pageBreakBefore: "always"
    }} /> */}
      {
        dataLongwis
          ? <Grid item width={"297mm"} container paddingX={10}>
            <Grid container >
              <Grid item container spacing={2}>
                <Grid item>
                  Nama PIC:  {dataLongwis.nama_pic}
                </Grid>
                <Grid item>
                  No Telp PIC:  {dataLongwis.kontak_pic}
                </Grid>
                <Grid item>
                  Nama Longwis:  {dataLongwis.nama_longwis}
                </Grid>
                <Grid item>
                  Kecamatan: {dataLongwis.kecamatan}
                </Grid>
                <Grid item>
                  Kelurahan: {dataLongwis.kelurahan}
                </Grid>
                <Grid item>
                  Alamat: {dataLongwis.alamat}
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{
              background: "GrayText",
              height: "2px",
              width: "100%",
              marginBottom: 1,
              marginX: 10,
            }}></Grid>
            <TableContainer sx={{
              marginTop: 2
            }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Jenis Pengecekan"}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Serial Number"}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Status"}</Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <Typography fontWeight={700}>{"Dokumentasi"}</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    dataLongwis.InternetLongwi
                      ? <TableRow>
                        <TableCell>
                          <Typography>
                            {'Internet Longwis'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {dataLongwis.InternetLongwi.serial_number}
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {dataLongwis.InternetLongwi.status === '2' ? 'Status Online' : 'Status Offline'}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Grid container direction={"row"} spacing={1}>
                            <Grid item xs={12}>
                              <ImageConverter
                                filePath={`${process.env.REACT_APP_API_URL}uploads/internet/${dataLongwis.InternetLongwi.foto_ont ? dataLongwis.InternetLongwi.foto_ont.replace('.jpg', '') : ''}`}
                                altText={dataLongwis.InternetLongwi.foto_ont}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <ImageConverter
                                filePath={`${process.env.REACT_APP_API_URL}uploads/internet/${dataLongwis.InternetLongwi.foto_no_seri ? dataLongwis.InternetLongwi.foto_no_seri.replace('.jpg', '') : ''}`}
                                altText={dataLongwis.InternetLongwi.foto_no_seri}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <ImageConverter
                                filePath={`${process.env.REACT_APP_API_URL}uploads/internet/${dataLongwis.InternetLongwi.foto_qr ? dataLongwis.InternetLongwi.foto_qr.replace('.jpg', '') : ''}`}
                                altText={dataLongwis.InternetLongwi.foto_qr}
                              />
                            </Grid>
                          </Grid>
                        </TableCell>
                      </TableRow>
                      : <></>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          : <></>
      }
    </div>
  );
}

export default PrintPDFAdminInternet;
